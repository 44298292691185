/*Training Section*/
.training-img {
    border-radius: 20px;
    width: 100%;
    height: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* -----------  Data Analytics - Analytics Section  -------------*/
.data-analytics-img {
    border-radius: 20px;
    width: 100%;
    height: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.data-analytics-icon {
    font-size: 36px;
    color: blue;
    margin-right: 10px;
}

.data-analytics-icon-small {
    font-size: 20px;
    color: blue;
    margin-right: 10px;
}

.data-analytics-icon-red-small {
    font-size: 20px;
    color: #800000;
    margin-right: 10px;
}

/* -----------   Data Analytics - Advantages and Disadvantages Section   -----------  */
.advantages-disadvantages-title {
    font-size: 25px;
    color: #696969;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 50px;
}

.advantages-disadvantages-desc {
    font-size: 18px;
    color: #696969;
    align-items: center;
    font-weight: lighter;
    margin-top: 20px;
}

.advantages ul {
    list-style: none;
    padding: 0;
}

.disadvantages ul {
    list-style: none;
    padding: 0;
}

.advantages-and-disadvantages {
    display: flex;
    justify-content: space-between;
}

.advantages,
.disadvantages {
    width: 45%;
}

@media (max-width: 768px) {
    .advantages-and-disadvantages {
        flex-direction: column;
    }

    .advantages,
    .disadvantages {
        width: 100%;
    }
}

/* ---------- Managed Services And Staff Augmentation ----------- */
.managed-services-SA-icon-small {
    font-size: 20px;
    color: blue;
    margin-right: 10px;
}

/* ----------
Training -  Training, Why Us, Certification Section &
Ent Data Management - Nurturing Asset Uncovering Insights
Managed Services And SA - Why Us

-----------  */

.training-benefit{
    font-size: 25px;
    color: #696969;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
}


.training-benefit-list {
    font-size: 20px;
    color: #696969;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-top: 10px;
}

.training-description {
    font-size: 18px;
    color: #7a7f9a;
    font-weight: lighter;
    margin-top: 15px;
    margin-bottom: 15px;
}

.training-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    column-gap: 70px;
    margin-top: 20px;
}

.training-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px; /* Adjust the value to make the training-card wider */
    border-radius: 20px;
    box-shadow: 0px 2px 15px rgba(145, 143, 143, 0.2);
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 50px;
    margin-top: 5px;
}

.training-card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.training-card-image {
    width: 100%;
    height: 210px;
    object-fit: cover;
}

.training-card-details {
    flex: 1;
    padding: 20px;
    background-color: white;
}

.training-card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.training-card-description {
    font-size: 15px;
    color: #797e9a;
    font-weight: lighter;
    margin-bottom: 15px;
    line-height: 1.4;
}

@media (min-width: 768px) {
    .training-card {
        width: calc(50% - 20px);
    }
}

/* --------------
Ent Data Mangmt - Nurturing Asset , Uncovering Insights &
Managed Services And Staff Augmentation - Benefits

------------ */

.number {
    font-size: 80px;
    font-weight: bold;
    color: blue;
    margin-right: 10px;
}

.icon {
    font-size: 36px;
    color: blue;
    margin-right: 10px;
}

.underline {
    color: blue;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    column-gap: 70px;
    margin-top: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px; /* Adjust the value to make the card wider */
    border-radius: 20px;
    box-shadow: 0px 2px 15px rgba(145, 143, 143, 0.2);
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 50px;
    margin-top: 5px;
}

.card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-image {
    width: 100%;
    height: 210px;
    object-fit: cover;
}

.card-details {
    flex: 1;
    padding: 20px;
    background-color: white;
}

.card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.card-description {
    font-size: 15px;
    color: #797e9a;
    font-weight: lighter;
    margin-bottom: 15px;
    line-height: 1.4;
}

@media (min-width: 768px) {
    .card {
        width: calc(50% - 20px);
    }
}

