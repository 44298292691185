.benefit {
    font-size: 30px;
    color: #344667;
    /*margin-bottom: 5px;*/
    display: flex;
    align-items: center;
    font-weight: bold;
}

.benefit-list {
    font-size: 20px;
    color: #344667;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.number {
    font-size: 80px;
    font-weight: bold;
    color: green;
    margin-right: 10px;
}

.description {
    font-size: 16px;
    color: #7a7f9a;
    font-weight: lighter;
    margin-top: 10px;
}

.icon {
    font-size: 48px;
    color: blue;
    margin-right: 10px;
}