.container-target-tech {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.box-target-tech{
    box-sizing: border-box;
    background-color: #ffffff;
    width: 150px;
    text-align: center;
    margin-bottom: 10px;
    /*max-width: 500px; !* Adjust the value to make the training-card wider *!*/
    border-radius: 10px;
    box-shadow: 1px 3px 4px rgb(203, 205, 208);
    cursor: pointer;
    padding: 10px 0px ;
}


.box-text-target-tech {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    color: #696969;
}


.box-target-tech img {
    width: 80px;
    height: 70px;
    justify-content: center;
}

/*Extra Small (Phones)*/
@media screen and (max-width: 576px){
    .box-target-tech {
        width: 47%;
        background-color: #ffffff;

    }
    .box-text-target-tech {
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        color: #696969;
    }
    .box-target-tech img {
        width: 60px;
        height: 50px;
        justify-content: center;
    }
}
/*Small (Tablets)*/
@media screen and (min-width: 577px) and (max-width: 768px) {
    .box-target-tech {
        width: 24%;
    }
    .box-text-target-tech {
        font-weight: bold;
        text-align: center;
        font-size: 13px;
        color: #696969;
    }
    .box-target-tech img {
        width: 60px;
        height: 50px;
        justify-content: center;
    }
}
/*Medium (Small Laptops, Tablets in Landscape)*/
@media screen and (min-width: 769px) and (max-width: 992px) {
    .box-target-tech {
        width: 24%;
        background-color: #ffffff;
    }
    .box-text-target-tech {
        font-weight: bold;
        text-align: center;
        font-size: 15px;
        color: #696969;
    }
    .box-target-tech img {
        width: 80px;
        height: 70px;
        justify-content: center;
    }
}
/*Large (Laptops, Desktops)*/
@media screen and (min-width: 993px) and (max-width: 1200px) {
    .box-target-tech {
        width: 24%;
        background-color: #ffffff;
    }
    .box-text-target-tech {
        font-weight: bold;
        text-align: center;
        font-size: 18px;
        color: #696969;
    }
    .box-target-tech img {
        width: 80px;
        height: 70px;
        justify-content: center;
    }
}

/*Extra Large (Large Desktops, Wide Screens):*/
@media screen and (min-width: 1201px) {
    .box-target-tech {
        width: 23%;
        background-color: #ffffff;
        margin-bottom: 1rem;
    }
    .box-text-target-tech {
        font-weight: bold;
        text-align: center;
        font-size: 18px;
        color: #696969;
    }
    .box-target-tech img {
        width: 80px;
        height: 70px;
        justify-content: center;
    }
}