.mobile-app-image {
    border-radius: 20px;
    width: 100%;
    height: 600px;
    box-shadow: 0px 2px 15px rgba(145, 143, 143, 0.2);
}

.web-app-image {
    border-radius: 20px;
    width: 100%;
    height: 600px;
    box-shadow: 0px 2px 15px rgba(145, 143, 143, 0.2);
}

.box-container-software-dev {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.box-bg-software-dev {
    width: 150px;
    text-align: center;
    margin-bottom: 10px;
    /*max-width: 500px; !* Adjust the value to make the training-card wider *!*/
    border-radius: 10px;
    box-shadow: 0px 2px 15px rgba(145, 143, 143, 0.2);
    cursor: pointer;
    padding: 10px 0px ;
    background-color: #ffffff;
}

.box-bg-software-dev img {
    width: 40px;
    height: 40px;
}
.box-text-software-dev{
    font-size: 14px;
    font-weight: bold;
}