
.our-story img {
    width: 500px;
    height: 400px;
    border-radius: 20px;
    margin-top: 10px;
}

/*Extra Small (Phones)*/
@media screen and (max-width: 576px){

    .our-story img {
        width: 280px;
        height: 200px;
        border: 20px;
        border-radius: 20px;
    }
}
/*Small (Tablets)*/
@media screen and (min-width: 577px) and (max-width: 768px) {

    .our-story img {
        width: 490px;
        height: 300px;
        border: 20px;
        border-radius: 20px;
    }
}
/*Medium (Small Laptops, Tablets in Landscape)*/
@media screen and (min-width: 769px) and (max-width: 992px) {

    .our-story img {
        width: 700px;
        height: 400px;
        border: 20px;
        border-radius: 20px;
    }
}
/*Large (Laptops, Desktops)*/
@media screen and (min-width: 993px) and (max-width: 1200px) {

    .our-story img {
        width: 380px;
        height: 400px;
        border-radius: 20px;
        margin-left: 100px;
        margin-top: 60px;
    }
}

/*Extra Large (Large Desktops, Wide Screens):*/
@media screen and (min-width: 1201px) {

    .our-story img {
        width: 500px;
        height: 400px;
        border-radius: 20px;
        margin-left: 100px;
        margin-top: 10px;
    }
}